.privacy__container {
  overflow: scroll;
  max-height: 60vh;
}

@media (min-width: 769px) {
  #privacy.login_modal {
    max-width: 1500px;
    width: 80%;
  }
}