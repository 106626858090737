#stream,
.stream__live,
.stream__live_container {
  height: 100%;
  overflow: hidden;
}
.stream_cavas {
  position: relative;
}
#stream .cartoober__header {
  top: 5%;
  position: absolute;
  z-index: 10;
}

.stream__live_container {
  background: green;
}

.stream__settings {
}

.stream_cavas__content video {
  display: none;
}

.stream_cavas__loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  display: flex;
  color: whitesmoke;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
}

.stream__live_container {
  background: black;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.stream_cavas__content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 8px;
  background: #222;
  width: 15rem;
}

.stream__settings {
  position: absolute;
  padding: 0.54rem;
  z-index: 100;
}
.stream__settings_options {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.stream__settings_option {
  display: inline-block;
  cursor: pointer;
  margin: 0 0.5rem;
  padding-bottom: 5px;
  font-weight: 100;
  color: #000;
}
.stream__settings_option.active {
  border-bottom: 2px solid #000;
}

.stream__settings_title {
  align-items: center;
  display: flex;
  justify-content: center;
}
.stream__settings_block {
  margin: 0 auto;
  width: 60%;
}
.stream__settings_title input {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 10px;
  display: flex;
  padding: 1rem 0.5rem;
  text-align: center;
  outline: none;
  width: 80%;
}

.show_title_btn {
  font-size: 2.5rem;
  display: inline-block;
  margin-left: 1rem;
  margin-bottom: -0.4rem;
}

.show_title_btn.active {
  color: #000;
}

.stream__menu {
  align-items: center;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 3%;
  top: 20%;
  z-index: 2;
}

.stream__menu_container {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.stream__menu_container .btn {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.stream__menu_container .btn:hover,
.stream__settings_close .btn:hover {
  color: #6a57d7;
}

.stream__settings_close {
  display: flex;
  justify-content: flex-end;
}
.stream__settings_close .btn {
  display: inline-block;
  padding: 0.5rem;
  pointer-events: all;
}
.stream__settings_content {
  max-width: 1000px;
  margin: 0 auto;
}

.fullscreen .stream__settings,
.fullscreen .stream_cavas__content,
.fullscreen .hide_fs,
/*.fullscreen .cartoober__header,*/
.fullscreen .stream__feedback {
  display: none;
}

.fullscreen .icon-fullscreen {
  opacity: 0.5;
}

/* Mobile devices */
@media (max-width: 480px) {
  #new_stream {
    padding-top: 1.5rem;
  }
  .stream_cavas__content {
    height: 100px;
    right: 3%;
    top: 60%;
    width: 100px;
  }
  .stream__settings_block {
    width: 80%;
  }
  .stream__settings_title {
    margin-bottom: 2rem;
  }
  .stream__settings {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    bottom: 0;
    left: 0;
    right: 0;
  }
  #stream .cartoober__header {
    left: 0;
    right: 0;
  }
  .stream__settings_options .icon-fullscreen {
    display: none;
  }
  .stream_cavas__content video {
    display: block;
    width: 100%;
    height: 100%;
  }
  .stream_cavas__content .guides {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    width: 100%;
    height: 100%;
  }
  .stream__feedback {
    display: none;
  }
}

@media (min-width: 481px) {
  
}

@media (min-width: 769px) {
  .stream__feedback {
    align-items: center;
    display: flex;
    color: whitesmoke;
    position: absolute;
    bottom: 3%;
    right: 3%;
  }
  .stream__feedback_text {
    margin-right: 1rem;
    text-align: right;
    text-shadow: 0 0 10px rgb(0 0 0 / 50%);
  }
  .stream__feedback_text h2,
  .stream__feedback_text p {
    margin: 0;
  }
  .stream__feedback_logo {
    background: white;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 0.5rem;
    height: 10vmin;
    width: 10vmin;
  }
  .stream__feedback_logo img {
    width: 100%;
    height: 100%;
  }
  #stream .cartoober__header {
    left: 3%;
  }
  .stream__settings_options {
    flex-direction: row-reverse;
  }
  .stream__menu_container  {
    background: transparent;
  }
  .stream__menu_container .btn {
    margin-bottom: 1.5rem;
  }
  .stream__menu_container .icon-sign-out {
    margin-bottom: 0;
  }
  .stream__settings_option  {
    margin: 0 1rem 0 0.5rem;
    font-size: 1.4rem;
  }
  .stream_cavas__content {
    height: 10vw;
    width: 15vw;
    right: 8%;
    top: 5%;
  }
  .stream__menu_active .stream_cavas__content {
    right: 28%;
  }
  .stream__menu {
    top: 5%;
  }
  .stream_cavas__content video {
    display: block;
    width: 100%;
    height: 100%;
  }
  .stream_cavas__content .guides {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    width: 100%;
    height: 100%;
  }
  .stream__settings .icon-close {
    font-size: 1rem;
  }
  .stream__settings_close {
    position: absolute;
    right: 0.5rem;
  }
  .stream__settings {
    background: white !important;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    right: 2%;
    top: 2%;
    bottom: 20%;
    width: 25%;
    padding: 0;
    overflow: hidden;
  }
  .stream__settings_content {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
  }
  .stream__settings_options {
    margin: 10px 0 0 0;
  }
  .stream__settings_option {
    padding-bottom: 1rem;
  }
  .stream__settings_buttons {
    background: #ebebeb;
    padding: 0.5rem 0.5rem 0 0.5rem;
    margin-bottom: 1.5rem;
  }
  .stream__settings_content {
    padding: 0.5rem;
  }
  .stream__settings_block {
    width: 80%;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  
}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {

}


/* Desktops, large screens  */
@media (min-width: 1921px){

}

@media (min-width: 769px) {
}