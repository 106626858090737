.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 3.5rem 0;
  text-align: center;
  width: 82%;
}

.footer__icon {
  width: 20vmin;
}

.footer .with_love {
  color: #000;
  font-size: 0.8rem;
  margin: 0 0 0.3rem 0;
  font-size: 1rem;
}

.footer .privacy {
  font-size: 0.6rem;
  margin: 0;
}
.footer__social_container{
  margin-bottom: 10px;
}
.footer__social_container span{
  margin: 0 15px;
}
.footer__social_container span svg{
  width: 25px;
  height: 25px;
  transform: scale(1);
  transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
}
.footer__social_container a{
  color: #000;
}
.footer__social_container a:hover span svg{
  transform: scale(1.2);
}

@media(max-width:600px){
  .footer__social_container span{
    margin: 0 10px;
  }
  .footer__social_container span svg{
    width: 15px;
    height: 15px;
  }
}