#contact {
  color: #000;
}

.contact__form {
  background: #e8c15a;
  border: solid 2px #000;
  box-sizing: border-box;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  margin: 3rem auto 0 auto;
  max-width: 1500px;
  padding: 3rem 7%;
  position: relative;
  width: 82%;
}

.contact__form .contact__form_container .main_bg {
  background: #e8c15a;
}

.contact__form .contact__form_container {
  width: 100%;
}

.contact__form_container .login_form__field {
  margin-bottom: 1.5rem;
}

.contact__form h2 {
  text-align: left;
  font-size: 2.5rem;
  margin-top: 0;
  width: 100%;
}

.contact__form_info,
.contact__form_fields {
  min-width: 40%;
}

.contact__form_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact__form_fields {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.contact__form_fields .send_btn {
  background: #000;
  border-radius: 2rem;
  display: inline-block;
  color: #fff;
  font-size: 0.7rem;
  padding: 0.7rem 2rem;
  text-align: center;
}

#contact .stream_button {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

#contact .stream_button .btn {
  background: #000;
  border-radius: 1rem;
  color: #fff;
  padding: 0.7rem 2rem;
  text-align: center;
}

@media(max-width:992px){
  .contact__form {
    display: block;
  }
  .contact__form_info{
    margin-bottom: 30px;
  }
}