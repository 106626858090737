.cartoober__loader {
  background: #ebebeb !important;
  display: flex;
}
.cartoober__loader_content {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  width: 60%;
}

.cartoober__loader_image {
  margin-bottom: 1rem;
  width: 40%;
}

.cartoober__loader_logo {
  margin-bottom: 3rem;
  width: 30%;
}

.cartoober__loader__progressbar {
  border-radius: 5px;
  border: solid 1px #000;
  background: linear-gradient(
    145deg,
    #000000 25%, #FFF 25%,
		#FFF 50%, #000000 50%,
		#000000 75%, #FFF 75%
  );
  margin-top: 0.5rem;
  height: 10px;
  width: 40%;
}

/* Mobile devices */
@media (max-width: 480px) {
  .cartoober__loader_content  {
    width: 90%;
  }
  .cartoober__loader_image {
    background: re;
    width: 50%;
  }
  .cartoober__loader_image {
    margin-bottom: 1rem;
    width: 80%;
  }
  
  .cartoober__loader_logo {
    margin-bottom: 3rem;
    width: 70%;
  }
  .cartoober__loader__progressbar {
    width: 70%;
  }
}