.auth__header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 3vmin 4vmin;
  text-align: center;
  width: 100%;
}

.auth__header .logo {
  left: 6vmin;
  max-width: 300px;
  position: absolute;
  width: 25%;
}

.auth__header .hidden {
  opacity: 0;
}

.auth__header .auth__spacer {
  width: 5%;
}

.auth__content {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.auth__content .login_form {
  width: 25%;
  max-width: 500px;
}

.auth__icon {
  position: absolute;
  left: 10%;
  width: 10rem;
}

#auth {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#auth .privacy {
  font-size: 0.8rem;
  text-align: center;
}

@media(max-width:992px){
  .auth__content .login_form {
    width: 35%;
  }
  .auth__icon {
    left: 7vw;
    width: 14vw;
  }
  .auth__header{
    display: block;
  }
  .auth__header .logo{
    position: relative;
    left: 0;
    width: 50%;
  }
}
@media(max-width:767px){
  .auth__content .login_form {
    width: 80%;
  }
  .auth__icon {
    left: auto;
    right: 5vw;
    width: 20vw;
    bottom: -20px;
  }
  .auth__header,
  .auth__content{
    width: 85%;
    margin: 0 auto;
  }
  .auth__header h1{
    font-size: 22px;
  }
  .auth__header .logo{
    width: 60%;
  }
}