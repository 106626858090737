.alert {
  border-radius: 10px;
  padding: 10px 40px;
  left: 40%;
  right: 40%;
  position: absolute;
  opacity: 0;
  margin: 0 auto;
  bottom: 8vh;
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  word-wrap: break-word;
  text-align: center;
  z-index: 100000;
}

.alert.visible {
  opacity: 1;
}

.alert.error {
  background: crimson;
  color: whitesmoke;
}

.alert.success {
  background: #000;
  color: whitesmoke;
}

/* Mobile devices */
@media (max-width: 480px) { 
  .alert {
    left: 15%;
    right: 15%;
  }
}