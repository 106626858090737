#new_stream {
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

#new_stream__blur {
  bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

#new_stream__blur.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

#new_stream,
#new_stream__blur {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: bottom !important;
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
}

.cartoober__header,
.new_stream__content {
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}


.new_stream__slider_block {
  align-items: center;
  display: flex;
  justify-content: center;
}
.new_stream__slider_block .avatar_slider__btn.left {
  margin-right: 1rem;
}
.new_stream__slider_block .avatar_slider__btn.right {
  margin-left: 1rem;
}

.new_stream__input {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.new_stream__input label {
  margin-bottom: 1rem;
  color: #000;
}

.new_stream__input input {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  text-align: center;
  outline: none;
  width: 80%;
}

.new_stream__container_submit {
  align-items: center;
  display: flex;
  justify-content: center;
}

.new_stream__container_submit .btn {
  background: black;
  border-radius: 1rem;
  color: whitesmoke;
  display: inline-block;
  padding: 0.9rem 2.5rem;
  font-size: 1.3rem;
  box-shadow: 0 17px 11px rgb(106 87 215 / 20%);
  margin-top: 1.6rem;
}
.new_stream__container_submit .btn_primary {
  pointer-events: all;
}
.new_stream__container_submit .btn_disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.new_stream__content {
  text-align: center;
  max-width: 900px;
}



.stream__settings_block_title {
  color: #000;
  margin: 0.5rem auto;
}

/* Mobile devices */
@media (max-width: 480px) {
  #new_stream {
    display: flex;
    flex-direction: column;
  }
  .new_stream__burger_button {
    margin-bottom: 0.5rem;
  }
  .new_stream__content {
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
    width: 90%;
  }
  .new_stream__content p {
    margin-top: 0;
  }
  #new_stream .cartoober__header {
    margin-bottom: 1.3rem;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .new_stream__burger_button {
    margin-bottom: 0.5rem;
  }
  .new_stream__content {
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    width: 90%;
  }
}

@media (min-width: 769px) {
  #new_stream {
    padding: 1rem;
  }
  #new_stream .avatar_slider__grid {
    display: none;
  }
  #new_stream .avatar_slider__container {
    display: block;
  }
  .new_stream__burger_button {
    display: none;
  }
  .new_stream__burger_button {
    margin-bottom: 0.5rem;
  }
  .new_stream__content {
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    width: 70%;
  }
  #new_stream .cartoober__header {
    max-width: 900px;
    justify-content: center;
    position: relative;
    width: 70%;
  }
  #new_stream .cartoober__header .logo {
    width: 40%;
  }
  #new_stream .cartoober__header .burguer_menu {
    right: 5%;
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .new_stream__content {
    width: 50%;
  }
  #new_stream .cartoober__header {
    width: 50%;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  
}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {
  
}

/* Desktops, large screens  */
@media (min-width: 1921px){

}
@media(max-height:900px){

}

@media(min-height:900px){
  #new_stream {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}