@media (min-width: 769px) {
  .cartoober__header.simple_mode {
    width: 20%;
  }
  .cartoober__header.simple_mode .burguer_menu {
    display: none;
  }
  .cartoober__header.simple_mode .logo {
    width: 100%;
  }
}