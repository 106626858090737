.contact_form {
  align-items: center;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
}

.contact_form .login_modal {
  border-radius: 2rem;
  box-sizing: border-box;
  padding: 1rem 2rem;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  width: 60%;
}

.contact_form .font_azo {
  color: black;
}
.contact_form__us .font_azo {
  margin-bottom: 0;
}
.contact_form__us p {
  margin-top: 0.5rem;
}

.contact_form input {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 10px;
  margin-bottom: 0.7rem;
  padding: 0.7rem 0.5rem;
  text-align: center;
  outline: none;
  width: 50%;
}

.contact_form .send_btn {
  display: inline-block;
  border-radius: 1rem;
  background: #000;
  padding: 0.7rem 2rem;
  color: #FFF;
  margin: 1rem 0;
}

.login_modal__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 2.5rem;
}

.login_modal__header .logo {
  width: 40%;
}

/* Mobile devices */
@media (max-width: 480px) {
  .contact_form .login_modal {
    width: 80%;
  }
  .contact_form input {
    width: 100%;
  }
  .login_modal__header .logo {
    width: 80%;
  }
  .login_modal .text_mini {
    font-size: 0.7rem;
    margin: 0;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {
}

/* Desktops, large screens  */
@media (min-width: 1921px) {
  
}


@media (min-width: 769px) {
  .login_modal__header {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .login_modal__header .icon-close {
    position: absolute;
    right: 0;
  }
  .login_modal .text_mini {
    font-size: 0.8rem;
    margin: 0;
  }
}