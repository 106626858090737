.header_full {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 3vmin 4vmin;
  width: 100%;
}

.header_full .logo {
  max-width: 300px;
  width: 25%;
}

.header_full__buttons {
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.header_full__buttons .btn {
  align-items: center;
  border: solid 2px #000;
  border-radius: 0.9rem;
  display: flex;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
}

.header_full__buttons .icon_image {
  display: inline-block;
  margin-right: 5px;
  width: 1.5rem;
}

.header_full__buttons .btn_black{
  padding: 0.5rem 2rem;
}

@media(max-width:767px){
  .header_full__buttons .btn {
    font-size: 0.8rem;
    padding: 0.2rem 0.8rem;
  }
  .header_full__buttons .icon_image {
    width: 1.2rem;
  }
  .header_full .logo {
    width: 35%;
  }
}

@media(max-width:600px){
  .header_full {
    display: block;
    text-align: center;
  }
  .header_full__buttons {
    justify-content: center;
  }
  .header_full .logo {
    width: 60%;
    margin-bottom: 15px;
  }
  .header_full__buttons .btn{
    width: 30%;
    justify-content: center;
  }
}