.hero {
  box-sizing: border-box;
  display: flex;
  color: #000;
  margin: 5rem auto 0 auto;
  max-width: 1500px;
  justify-content: space-between;
  width: 82%;
}

.hero__info {
  width: 60%;
}
.hero__info_title{
  margin-top: 0px;
  margin-bottom: 30px;
}
.hero__info_title h2,
.hero__info_title span {
  font-size: 62px;
  margin: 0;
  padding: 0;
  font-family: kansasnew, serif;
  font-weight: 600;
}


.hero__login {
  width: 40%;
}

.hero .login_form {
  box-sizing: border-box;
  margin-left: auto;
  width: 80%;
}
.hero .empty_space {
  height: 10px;
  width: 100%;
}

.video_container{
  position: relative;
  width: 100%;
}

@media(max-width:1600px){
  .hero__info_title h2,
  .hero__info_title span{
    font-size: 3.8vw;
  }
}
@media(max-width:1200px){
  
  .hero__info, .hero__login {
    width: 50%;
  }
  .login_form {
    border-radius: 4rem;
    padding: 4rem 2rem;
  }
  .hero .login_form {
    width: 85%;
  }
}
@media(max-width:992px){
  .hero {
    display: block;
  }
  .hero__info, .hero__login {
    width: 100%;
  }
  .hero .login_form{
    margin: 50px auto;
    position: relative;
  }
  .hero .login_form {
    width: 100%;
    padding: 4rem 2rem;
    border-radius: 3rem;
  }
}
@media(max-width:600px){
  .hero__info_title h2, .hero__info_title span {
    font-size: 6vw;
  }
  .hero {
    margin: 1.5rem auto 0 auto;
  }
  .hero__info_title {
    margin-top: 0px;
  }
  .hero__info_title{
    text-align: center;
  }
}