.home_instructions {
  background: #038bd3;
  box-sizing: border-box;
  border-radius: 2rem;
  border: solid 2px #000;
  color: #000;
  padding: 7rem 8%;
  margin-top: 5.5rem !important;
  text-align: center;
  position: relative;
}

.home_instructions__logo {
  position: absolute;
  right: 7%;
  top: -3vw;
  width: 10rem;
}

.home_instructions__container {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
}

.home_instructions__instruction p,
.home_instructions__instruction span {
  font-size: 1.8rem;
}

.home_instructions__instruction p {
  margin: 0 0 0.5rem 0;
}

.home_instructions .btn {
  background: #000;
  border-radius: 1rem;
  color: whitesmoke;
  padding: 0.5rem 2.5rem;
  padding: 1rem 2rem;
  font-size: 25px;
  font-weight: 600;
}

.home_instructions__title h2 {
  font-size: 70px;
  margin: 0;
  font-family: kansasnew, serif;
  font-weight: 600;
}

@media(max-width:1600px){
  .home_instructions__title h2{
    font-size: 5vw;
  }
}
@media(max-width:992px){
  .home_instructions__logo {
    top: -7vw;
    width: 7rem;
  }
  .home_instructions__container {
    display: block;
  }
  .home_instructions__instruction{
    margin-bottom: 30px;
  }
  .home_instructions__instruction p, .home_instructions__instruction span {
    font-size: 1.3rem;
  }
  .home_instructions__container {
    margin: 2rem 0;
  }
  .home_instructions {
    padding: 5rem 8%;
  }
}